@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  margin-bottom: 100px;
  justify-content: flex-start;
  position: relative;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.defaultUserFields {
}

.name {
  display: flex;
  justify-content: space-between;
}

.customField {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.row,
.customFields {
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstNameRoot {
  width: calc(34% - 8px);
}

.lastNameRoot {
  width: calc(66% - 8px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 16%;
  padding-bottom: 16px;
}

.buttonWrapper {
  display: flex;
  width: 100%;
  gap: 25%;
  justify-content: center;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.progress {
  height: 100%;
  background-color: var(--colorPrimaryButton);
  border-radius: 4px;
}

.contactSubmitButton {
  width: 50%;
  padding: 8px;
  border-radius: 4px;
  border: none;
  color: #f0f0f0;
  margin-top: 16px;
  background-color: var(--colorPrimaryButton);
}

.customInput {
  margin-top: 24px;
  width: 85%;
}

.scrollButton {
  position: absolute;
  bottom: 35px;
  right: -35px;
  background-color: var(--colorPrimaryButton);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.scrollButton:hover {
  color: var(--colorGrey100);
}

.stepButton {
  max-width: 250px;
}

.formTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.formDescription {
  color: #666;
  margin-bottom: 1.5rem;
}

.formGroup {
  margin-bottom: 1rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.waitlistToggle {
  margin-top: 1rem;
  color: var(--colorPrimaryButton);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  text-decoration: underline;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 0.25rem;
}

.input:focus {
  outline: none;
  border-color: #666;
}

.submitButton {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--colorPrimaryButton);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.submitButton:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--marketplaceColor);
}

.alert {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
}

.successAlert {
  background-color: #dcfce7;
  color: #166534;
  border: 1px solid #bbf7d0;
}

.errorAlert {
  background-color: #fee2e2;
  color: #991b1b;
  border: 1px solid #fecaca;
}
